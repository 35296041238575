import Collapsible from 'react-collapsible';
import SectionHeader from './SectionHeader.js';
import './Section.css';

function Section(props) {

    const { caption, children, color, explanation, info,
            infoHeader, infoChildren, nomargin, open,
            setOpenFlag, targetId } = props;

    return (
         <section className={`lighten-4 color-box ${color}`}>
            {<Collapsible open={open}
                    trigger={
                        <SectionHeader
                            caption={caption}
                            explanation={explanation}
                            collapsible={true}
                            info={info}
                            infoHeader={infoHeader}
                            infoChildren={infoChildren}
                        />
                    }
                    handleTriggerClick={
                        () => {
                            setOpenFlag(!open);
                        }
                    }
                    onOpen={() => setOpenFlag(true)}
                    onClose={() => setOpenFlag(false)}
                >
                    <div className="nocolor-box" id={targetId}>
                        <div className={nomargin ? "box-padding row nomargin" : "box-padding row"}>
                            {children}
                        </div>
                    </div>
                </Collapsible>
            }
        </section>
    );
}

export default Section;
