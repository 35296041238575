import { useContext, useState } from "react";
import { Button, Icon, Modal, Table, TextInput } from "react-materialize";
import { CcmrContext } from "../../../App";

function DblpSearchModal(props) {
    const { color } = props;
    const { resizeFields } = useContext(CcmrContext);
    const [searchResults, setSearchResults] = useState(null);
    const [searchModalOpen, setSearchModalOpen] = useState(false);

    function authorsString(authors) {
        if (Array.isArray(authors)) {
            const authorNames = authors.map(x => x.text.replace(/\s*\d+$/, ""));

            if (authors.length === 1) {
                // This case shouldn't occur, but just in case...
                return authorNames[0];
            }

            if (authors.length === 2) {
                return authorNames.join(" and ");
            }

            return authorNames.slice(0, -1).join(", ") + ", and " + authorNames.slice(-1);
        }

        if (typeof authors === "object") {
            return authors.text ?? "";
        }

        return "";
    }

    function selectContent(info) {
        // Caution: this is not standard React-style code.  Instead, we are
        // manipulating the DOM directly.  There be dragons here.

        const titleTextArea = document.getElementById("title");
        const authorsTextArea = document.getElementById("authors");
        const biblioTextArea = document.getElementById("biblio");

        titleTextArea.value = info.title.replace(/[.]$/, "");
        authorsTextArea.value = authorsString(info.authors?.author);
        biblioTextArea.value = info.ee;

        resizeFields([titleTextArea, authorsTextArea, biblioTextArea]);

        setSearchModalOpen(false);
    }

    async function search() {
        const terms = document.getElementById("dblp").value ?? "";

        if (terms.length > 0) {
            setSearchResults("Searching DBLP...");

            await fetch(`https://dblp.org/search/publ/api?q=${terms}&format=json`)
                .then(response => response.json())
                .then(
                    (result) => {
                        const resultList = result?.result?.hits?.hit;

                        if (resultList) {
                            let results = [
                                <tr key="rc"><td>Retrieved {resultList.length} results</td></tr>
                            ];

                            resultList.forEach((hit, index) => {
                                const info = hit.info;

                                results.push(<tr key={`r${index}`}>
                                    <td><b>{info.title}</b> {authorsString(info.authors?.author)}, {info.year}<br />
                                        <a href={info.ee} target="_blank" rel="noreferrer">{info.ee}</a></td>
                                    <td>
                                        <Button
                                            node="button"
                                            waves="light"
                                            className="min-width"
                                            onClick={() => { selectContent(info); }}
                                        >
                                            Select <Icon right>content_copy</Icon>
                                        </Button>
                                    </td>
                                </tr>);
                            });

                            setSearchResults(<Table><tbody>{results}</tbody></Table>);
                        } else {
                            setSearchResults("No results found");
                        }
                    },

                    (errorResult) => {
                        console.error("Error fetching DBLP search results", errorResult);
                        setSearchResults(`Error fetching DBLP search results ${errorResult}`);
                    }
                );
        }
    }

    return (
        <Modal
            id="dblpSearch"
            header="Lookup on DBLP"
            fixedFooter={true}
            open={searchModalOpen}
            options={{
                onCloseStart: () => {
                    setSearchModalOpen(false);
                },
                onOpenStart: () => {
                    setSearchModalOpen(true);
                }
            }}
            actions={[
                <Button key="d1" node="button" onClick={search}>Search <Icon right>search</Icon></Button>,
                <Button key="d2" modal="close" node="button" className="padding-left">Cancel</Button>
            ]}
            trigger={
                <Button
                    node="button"
                    waves="light"
                    className={`end-margin ${color}`}
                >
                    Lookup on DBLP <Icon right>open_in_browser</Icon>
                </Button>
            }>
            <TextInput
                id="dblp"
                icon={<Icon>search</Icon>}
                type="text"
                label="Terms to search (title, author, etc.)"
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        search();
                    }
                }}
            />
            {searchResults}
        </Modal>
    );
}

export default DblpSearchModal;