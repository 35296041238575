import Section from "../../../Widgets/Section";
import TextField from '../../../Widgets/TextField';
import {
    additionalCaption, additionalExplanation, additionalInfoChildren,
    additionalInfoHeader, additionalInfoTooltip, additionalInfoLabel
} from './UserVisibleText';
import './AdditionalInfoSection.css';

function AdditionalInfoSection(props) {
    const { form, stepFourVisible, setStepFourVisible } = props;

    return (
        <Section targetId="form-context"
            caption={additionalCaption}
            explanation={additionalExplanation}
            info={additionalInfoTooltip}
            infoHeader={additionalInfoHeader}
            infoChildren={<p>{additionalInfoChildren}</p>}
            color="deep-orange"
            collapsible={true}
            open={stepFourVisible}
            setOpenFlag={setStepFourVisible}
        >
            <TextField
                id="additional_info"
                label={additionalInfoLabel}
                span="s12"
                defaultValue={form?.additional_info ?? ""}
            />
        </Section>
    );
}

export default AdditionalInfoSection;