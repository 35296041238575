import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "react-materialize";

const SignupButton = () => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    const handleSignUp = async () => {
        await loginWithRedirect({
            prompt: "login",
            screen_hint: "signup",
            appState: {
                returnTo: "/"
            }
        });
    };

    return (
        !isAuthenticated && (
            <Button
                className="blue padding-end no-padding-left"
                waves="light"
                small
                onClick={handleSignUp}
            >
                Create Account
            </Button>
        )
    );
};

export default SignupButton;
