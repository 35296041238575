import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-materialize";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../Constants.js";
import { CcmrContext } from "../../App.js";

function FormList(props) {
    const { token } = useContext(CcmrContext);
    const [collections, setCollections] = useState({});

    useEffect(() => {
        async function fetchFormsList() {
            fetch(`${BASE_URL}forms`, {
                headers: (token) ? { Authorization: `Bearer ${token}` } : {},
            })
                .then(response => response.json())
                .then(
                    (result) => {
                        setCollections(result);
                    },

                    (error) => {
                        console.error("Error", error);
                    }
                );
        }

        fetchFormsList();
    }, [token]);

    let collectionList = [];

    if (collections.collections) {
        collections.collections.forEach(collection => {
            collectionList.push(
                <div key={collection.collection} className="row">
                    <div className="col s12"><h5>{collection.collection}</h5></div>
                </div>
            );

            const worksList = [];

            collection.forms.forEach(form => {
                worksList.push(
                    <tr key={form.id}>
                        <td>
                            <Link to={`/viewForm/${form.id}`}>{form.title}</Link>
                        </td>
                        <td>{form.authors}</td>
                    </tr>
                );
            });

            collectionList.push(
                <Table key={`tk${collection.collection}`}>
                    <thead>
                        <tr>
                            <th data-field="title">Title</th>
                            <th data-field="authors">Authors</th>
                        </tr>
                    </thead>
                    <tbody>
                        {worksList}
                    </tbody>
                </Table>
            );
        });
    }

    return (
        <div className="container white padding-top">
            <div className="row padding-top">
                {collectionList}
            </div>
        </div>
    );
}

export default FormList;