import ExpandCollapse from './ExpandCollapse.js';
import InfoButton from './InfoButton';
import './SectionHeader.css';

function FullRow(props) {
    return (
        <div className={`full-row ${props.className}`}>
            {props.children}
        </div>
    );
}

function SectionHeader(props) {
    return (
        <FullRow className={`${props.collapsible ? "clickable" : "static"} ${props.className ? props.className : ""}`}>
            {
                props.collapsible
                ? <ExpandCollapse targetId={props.targetId} collapsible={props.collapsible} />
                : null
            }
            {props.children}
            <b>{props.caption}<InfoButton {...props} /></b>
            {props.explanation ? <br /> : null}
            {props.explanation ? <p>{props.explanation}<InfoButton {...props} /></p> : null}
        </FullRow>
    );
}

export default SectionHeader;