import { classForSpanType } from './TextField.js';
import './MultiSelect.css';

function MultiSelect(props) {
    const { checks, labels, setChecks } = props;
    const items = [];
    let index = 0;

    function handleChange(name, key) {
        const newChecks = Object.assign({}, checks);

        newChecks[key] = document.querySelectorAll(`input[name="${name}"]`)[0].checked;
        setChecks(newChecks);
    }

    for (const value of props.items) {
        index += 1;

        const key = "check" + index;
        const name = `${props.fieldKey}-${index}`;

        items.push(
            <label key={name}>
                <input
                    type="checkbox"
                    name={name}
                    value={index}
                    onChange={() => { handleChange(name, key); }}
                    defaultChecked={labels.includes(value)}
                />
                <span>{value}</span>
            </label>
        );

        if (index < props.items.length) {
            items.push(<br key={`br${props.fieldKey}-${index}`} />);
        }
    }

    return (
        <div className={`col ${props.className} ${classForSpanType(props.span)}`}>
            {items}
        </div>
    );
}

export default MultiSelect;