import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Icon } from "react-materialize";
import { toast } from "materialize-css";
import { CcmrContext } from "../../../App.js";
import { isTypeI, isTypeII, isTypeIII } from "../../../Ccmr3Form.mjs";
import { BASE_URL } from "../../../Constants.js";
import AdditionalInfoSection from "./AdditionalInfoSection.js";
import BibliographicSection from "./BibliographicSection.js";
import ContributionsSection from "./ContributionsSection.js";
import KeyQuestionsSection from "./KeyQuestionsSection.js";
import { methodList, natureList, purposeList, visionList } from "./UserVisibleText.js";

const idToListMapping = {
    i_nature: natureList,
    ii_purpose: purposeList,
    ii_nature: methodList,
    iii_contrib_type: visionList
};

function EditForm(props) {
    const { form, formId } = props;
    const { token } = useContext(CcmrContext);
    const [stepOneVisible, setStepOneVisible] = useState(true);
    const [stepTwoVisible, setStepTwoVisible] = useState(false);
    const [stepThreeVisible, setStepThreeVisible] = useState(false);
    const [stepFourVisible, setStepFourVisible] = useState(false);
    const [typeOneVisible, setTypeOneVisible] = useState(isTypeI(form));
    const [typeTwoVisible, setTypeTwoVisible] = useState(isTypeII(form));
    const [typeThreeVisible, setTypeThreeVisible] = useState(isTypeIII(form));
    const color = "turquoise";
    const navigate = useNavigate();

    function encodedFormValues() {
        let encodedValues = { formId };

        for (const element of document.querySelectorAll("form input[type=text], form textarea")) {
            if (element.value !== null) {
                encodedValues[element.id] = element.value;
            }
        }

        const checkedValues = {};

        for (const element of document.querySelectorAll("form input[type=checkbox]:checked")) {
            const [id, indexString] = element.name.split("-");
            const index = Number(indexString) - 1;

            if (id && !isNaN(index) && Object.keys(idToListMapping).includes(id)) {
                if (checkedValues[id]) {
                    checkedValues[id] += `|${idToListMapping[id][index]}`;
                } else {
                    checkedValues[id] = idToListMapping[id][index];
                }
            }
        }

        Object.keys(checkedValues).forEach((key) => {
            encodedValues[key] = checkedValues[key];
        });

        return encodedValues;
    }

    async function submitForm(event) {
        // Prevent form submission
        event.preventDefault();

        const body = JSON.stringify(encodedFormValues());

        fetch(`${BASE_URL}saveForm`, {
            method: "POST",
            cache: "no-cache",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: body
        })
            .then((response) => response.json())
            .then(
                (result) => {
                    toast({ html: "Changes saved" }, 3000);
                    navigate(`/forms/${result.formId}`);
                },

                (errorResult) => {
                    console.error("Error", errorResult);
                    toast({ html: `Error saving changes: ${errorResult}` }, 5000);
                }
            );
    }

    return (
        <div className="container white padding-top">
            <form>
                <BibliographicSection
                    setStepOneVisible={setStepOneVisible}
                    setStepTwoVisible={setStepTwoVisible}
                    stepOneVisible={stepOneVisible}
                    form={form}
                />

                <KeyQuestionsSection
                    setStepThreeVisible={setStepThreeVisible}
                    setStepTwoVisible={setStepTwoVisible}
                    stepTwoVisible={stepTwoVisible}
                    form={form}
                />

                <ContributionsSection
                    setStepThreeVisible={setStepThreeVisible}
                    setStepFourVisible={setStepFourVisible}
                    stepThreeVisible={stepThreeVisible}
                    typeOneVisible={typeOneVisible}
                    typeTwoVisible={typeTwoVisible}
                    typeThreeVisible={typeThreeVisible}
                    // typeFourVisible={typeFourVisible}
                    setTypeOneVisible={setTypeOneVisible}
                    setTypeTwoVisible={setTypeTwoVisible}
                    setTypeThreeVisible={setTypeThreeVisible}
                    // setTypeFourVisible={setTypeFourVisible}
                    form={form}
                />

                <AdditionalInfoSection
                    stepFourVisible={stepFourVisible}
                    setStepFourVisible={setStepFourVisible}
                    form={form}
                />

                <div className="row padding-top">
                    <div className="col s12 align-end">
                        <Button node="button" waves="light" className={color} onClick={submitForm}>
                            Submit <Icon right>send</Icon>
                        </Button>
                    </div>
                </div>
            </form>
            <br />
            <br />
            <br />
            <br />
        </div>
    );
}

export default EditForm;
