import InfoButton from './InfoButton';
import LabelField from './LabelField';
import TextField from './TextField.js';
import './LabelTextGroup.css';

function LabelTextGroup(props) {
    return (
        <div className="group">
            <LabelField span="left">
                <b className="prompt">{props.prompt}</b>
                <InfoButton {...props} />
            </LabelField>
            <TextField
                className={props.className}
                span="right"
                id={props.id}
                label={props.label}
                defaultValue={props.form ? props.form[props.id] ?? "" : ""}
            />
        </div>
    );
}

export default LabelTextGroup;