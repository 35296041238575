import InfoButton from "./InfoButton";
import LabelField from "./LabelField";
import MultiSelectTextField from "./MultiSelectTextField";

function LabelSelectTextGroup2Column(props) {
    return (
        <div className="group">
            <LabelField span="full">
                <b className="prompt">{props.prompt}</b>
                <InfoButton {...props} />
            </LabelField>
            <MultiSelectTextField
                className="pad-top s12 tight-header"
                {...props}
            />
        </div>
    );
}

export default LabelSelectTextGroup2Column;