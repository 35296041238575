import { useCallback } from 'react';
import { Modal } from 'react-materialize';
import './InfoButton.css';

function InfoButton(props) {
    const { info, infoHeader, infoChildren } = props;
    let fixedFooter = false;

    const handleClick = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
    }, []);

    let children = infoChildren;

    if (typeof children !== "string") {
        fixedFooter = true;
    }

    if (!children && info !== undefined) {
        children = <p>{info}</p>;
    }

    return (info !== undefined)
        ?
        <Modal
            header={infoHeader}
            fixedFooter={fixedFooter}
            trigger={
                <button
                    className={"btn-info waves-effect waves-light"}
                    onClick={handleClick}
                    title={info}
                >
                    <i className={"material-icons"}>info</i>
                </button >
            }
        >
            {children}
        </Modal >
        :
        <span />
        ;
}

export default InfoButton;  