import { Table } from "react-materialize";

// Bibliographic information
export const bibHeader = "About this Work";
export const bibCaption = "1. Provide bibliographic information.";
export const bibLabelAuthors = "Authors";
export const bibLabelBiblio = "Other bibliographic information";
export const bibLabelTitle = "Title";
export const bibLabelCommunity =
    "CM-related research communities this work is mainly associated with";
export const bibCommunityTooltip =
    "E.g. business process modeling, database design, ontology, software engineering, etc.";
export const bibCommunityInfoHeader = "CM-related Research Communities";
export const bibCommunityInfoChildren = (
    <div>
        <p>
            Knowing the research community (or communities) an author identifies with can help a
            reader understand the background and point-of-view of the author and the terminology
            used in the work.
        </p>
        <p>
            Examples of CM-related research communities (with some common terms and venues) include
            the following:
        </p>
        <Table>
            <thead>
                <tr>
                    <th data-field="id">Community</th>
                    <th data-field="name">Common Terms</th>
                    <th data-field="price">Typical Venues</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Business process modeling</td>
                    <td>process, activity, task, ...</td>
                    <td>BPM, CAiSE, ER, MODELS, ISD, RE, ...</td>
                </tr>
                <tr>
                    <td>Database design</td>
                    <td>schema, data model, entity, ...</td>
                    <td>SIGMOD, VLDB, ER, TODS, J. on Data Semantics, ...</td>
                </tr>
                <tr>
                    <td>Ontology</td>
                    <td>foundational ontology, upper/lower ontology, ...</td>
                    <td>FOIS, ER, ...</td>
                </tr>
                <tr>
                    <td>Requirements engineering</td>
                    <td>requirements model, ER diagram, goal model, ...</td>
                    <td>RE, CAiSE, ESEM, ER, ...</td>
                </tr>
            </tbody>
        </Table>
        <p>
            This table only shows a few CM-related research communities. There are <b>many</b> more.
        </p>
    </div>
);
export const nextButtonLabel = "Next";

// Key questions
export const keyQuestions = "2. Identify the significant contribution of this work.";
export const keyQuestion1 = "What key questions are addressed in this work?";
export const keyQuestion2 = "What is the central contribution of this work?";
export const keyQuestion3 = "How is this work relevant to conceptual modeling?";
export const keyQuestionUsers =
    "If relevant to the contribution, who are the CM users mentioned, assumed, or targeted in this work?";
export const keyQuestionDomain =
    "If an application of CM is a significant part of the contribution, what is the application domain?";
export const keyLabelQuestions = "Key questions";
export const keyLabelContribution = "Central contribution";
export const keyLabelRelevance = "Relevance to CM";
export const keyLabelUsers = "Intended CM users";
export const keyLabelDomain = "Application domain";

// Contributions
export const contribHeader = "Characterizing the Significant Contribution";
export const contribCaption = "3. Characterize the significant contribution of this work.";
export const contribCaptionI = `I. The work contributes a conceptual model, or a language,\
 metamodel, notation, etc. for CM.`;
export const contribInfoI = `Use this subsection only when a primary contribution of the work\
 is a model, language, metamodel, notation, etc.`;
export const contribCaptionII = `II. The work contributes a method, process, algorithm, tool,\
 etc. for CM.`;
export const contribInfoII = `Use this subsection only when a primary contribution of the work\
 is a method, process, algorithm, tool etc. for conceptual modeling.`;
export const contribCaptionIII = `III. The work contributes a vision, philosophy, principles,\
 review, etc. of the field of CM.`;
export const contribInfoIII = `Use this subsection only when a primary contribution of the work\
 is a vision, philosophy, some principles, a review, etc. for conceptual modeling.`;
export const describeBriefly = "Describe (optional)";
export const describeOther = "Describe other";
export const contribExplanation = `Depending on the type of conceptual modeling contribution,\
 fill out (typically just one) subsection. Focus on the main contribution\
 related to conceptual modeling.`;
export const labelPurpose = "Purpose";
export const prompt3a2 = "What is its purpose?";
export const prompt3a3 = "What is the nature of the contribution?";
export const contribNature = "Nature of the contribution";
export const labelOther = "Description of other";
export const prompt3a5 =
    "Which of the following dimensions of CM does this contribution address? (optional)";
export const prompt3a5b = "Describe the contribution";
export const triptychInfo = "See “The triptych of conceptual modeling” by Mayr and Thalheim, 2020.";
export const triptychInfoHeader = "Dimensions of Conceptual Modeling";
export const triptychInfoChildren = (
    <div>
        <p>
            The{" "}
            <a
                href={"https://link.springer.com/article/10.1007/s10270-020-00836-z"}
                target={"_blank"}
                rel={"noreferrer"}
            >
                Triptych of Conceptual Modeling
            </a>{" "}
            is a paradigm used to illustrate the three dimensions of conceptual modeling:
        </p>
        <ol>
            <li>
                <p>
                    The{" "}
                    <b>
                        <i>linguistic dimension</i>
                    </b>{" "}
                    defines the language used for model representation. “Language” is used here in a
                    broad sense and can include textual, graphical, audio-visual, and other physical
                    forms of representation. In the case of well-known modeling languages such as
                    UML or BPMN, it is the respective notation together with the grammatical
                    composition rules.
                </p>
                <p>
                    If in this language terms are borrowed/used from a natural language (e.g.,
                    English), sentences (textual, diagrams etc.) can be formed with it, from which a
                    speaker of this language can intuitively derive meaning.
                </p>
            </li>
            <li>
                <p>
                    The{" "}
                    <b>
                        <i>model dimension</i>
                    </b>{" "}
                    defines the structure of the models in different levels of an abstraction
                    hierarchy. This is roughly comparable to OMG’s MetaObject Facility (MOF) where a
                    metamodel defines the intended modeling concepts; for example, in the original
                    entity-relationship model, these include “Entity Set,” “Relationship Set,”
                    “Attributes,” “Value Set,” “Role”. For a concrete application, these modeling
                    concepts can be instantiated, for example, as an entity set “Customer” to which
                    certain attributes such as “Name”, “Phone Number”, etc. are assigned. This in
                    turn can be instantiated to represent a concrete object such as the customer
                    Smith.
                </p>
                <p>
                    Note that the combination of model structure and natural language identifiers
                    results in a construct which can be interpreted not only (as in the linguistic
                    dimension) based on the natural language terminology and syntax but also on the
                    basis of the modeling concepts and their interdependencies defined in the
                    metamodel. We refer to this as “a priori semantics”. However, even such models
                    might be ambiguous due to the multi-faceted nature of natural language.
                </p>
            </li>
            <li>
                <p>
                    The{" "}
                    <b>
                        <i>semantics dimension</i>
                    </b>{" "}
                    provides a unique meaning of the elements of the model dimension (at all
                    levels). In fact, the presence of the semantics dimension is what distinguishes
                    a conceptual model from other types of models:{" "}
                    <i>
                        A model is conceptual if it has a complete foundation in the semantics
                        dimension, i.e., if all its constituents are defined there.
                    </i>{" "}
                    There are various ways to provide the semantics for each concept that appears in
                    the model dimension. One is to provide an ontological or encyclopedic grounding
                    for each concept. Other possibilities include providing a thesaurus, data
                    dictionary, or glossary such as a “company manual” that defines all the terms
                    used in the company in question.
                </p>
            </li>
        </ol>
        <p>
            For more details, see{" "}
            <a
                href={"https://link.springer.com/article/10.1007/s10270-020-00836-z"}
                target={"_blank"}
                rel={"noreferrer"}
            >
                “The triptych of conceptual modeling“
            </a>{" "}
            by Heinrich C. Mayr and Bernhard Thalheim, published in{" "}
            <i>Software and Systems Modeling</i>, Vol. 20, issue 1, Springer, February 2021.
        </p>
        <p>The triptych is illustrated in the following figure:</p>
        <p className="center-content">
            <img
                src={
                    "https://media.springernature.com/full/springer-static/image/art%3A10.1007%2Fs10270-020-00836-z/MediaObjects/10270_2020_836_Fig5_HTML.png"
                }
                width={"80%"}
                alt={"Triptych diagram"}
            />
        </p>
    </div>
);

export const prompt3b1 = "What is its purpose?";
// For instance, description, specification, ...
export const prompt3b2 = "What is the nature of the significant contribution?";
export const prompt3c = "What type of contribution is made?";
export const labelApplicationDomain = `Domain`;
export const promptApplicationDomain = `What is the application domain?`;
export const applicationType = "Type of contribution";

export const labelSpecificAccomplishment = `Specific accomplishment`;
export const promptSpecificAccomplishment = `Describe the specific accomplishment`;

export const methodList = [
    "Definition",
    "Extension",
    "Comparison",
    "Evaluation through a case study",
    "Evaluation through a performance study",
    "Evaluation through a user study",
    "Discussion of philosophical basis",
    "Formalization",
    "Investigation of expressive power or other formal properties",
    "Other (describe)"
];
export const natureList = [
    "Definition",
    "Extension",
    "Comparison",
    "Evaluation through a case study",
    "Evaluation through a performance study",
    "Evaluation through a user study",
    "Discussion of philosophical basis",
    "Formalization",
    "Investigation of expressive power or other formal properties",
    "Other (describe)"
];
export const purposeList = [
    "CM validation, simulation, verification",
    "CM instance generation",
    "CM extraction, reverse engineering",
    "CM translation downward (to other representations/implementations)",
    "CM integration, exchange, migration",
    "Query or keyword search over data in a CM",
    "Reuse of a CM",
    "Other (describe)"
];
export const visionList = [
    "Vision for the field of CM (or a subfield)",
    "Theoretical or philosophical issues surrounding CM or CM research",
    "Principles for CM, CM languages, tools, or CM research",
    "Proposal or viewpoint",
    "Broad survey of (perhaps part of) the field of CM",
    "Pedagogy or education for CM",
    "Application of CM to a significant or challenging domain",
    "Other (describe)"
];
export const dimensionsList = [
    "Semantic grounding",
    "Modeling artifacts",
    "Linguistic development"
];
export const contributionsNextButtonLabel = "Done with Contributions";

// Additional information
export const additionalHeader = "Additional Information";
export const additionalCaption =
    "4. Provide additional information — comments, explanations, insights, descriptions (optional).";
export const additionalExplanation = "";
export const additionalInfoHeader = "About this section";
export const additionalInfoChildren = `You may write whatever kinds of notes you'd like.\
 You could write about reasons why the paper is important,\
 related work that the authors did not reference, or anything else.`;
export const additionalInfoTooltip = "Give any additional information you wish to include";
export const additionalInfoLabel = "";
