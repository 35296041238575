import React from "react";
import Collapsible from "react-collapsible";
import DashedBorderGroup from "./DashedBorderGroup.js";
import DashedBorderGroup2Column from "./DashedBorderGroup2Column.js";
import LabelField from "./LabelField.js";
import LabelSelectTextGroup from "./LabelSelectTextGroup.js";
import LabelTextGroup from "./LabelTextGroup.js";
import SectionHeader from "./SectionHeader.js";
import InfoButton from "./InfoButton";
import "./ExpandingSubsection.css";

function ExpandingSubsection(props) {
    const { form, open, setOpenFlag, targetId, template } = props;
    const { color, type, caption, explanation } = template[0];

    function filledTemplate(template) {
        const tree = [];
        let nextKey = 1;

        for (const item of template) {
            const itemKey = `key${type}-${nextKey}`;

            nextKey += 1;

            switch (item.type) {
                case "DashedBorderGroup":
                    tree.push(<DashedBorderGroup key={itemKey} {...item} form={form} />);
                    break;
                case "DashedBorderGroup2Column":
                    tree.push(<DashedBorderGroup2Column key={itemKey} {...item} form={form} />);
                    break;
                case "LabelField":
                    tree.push(<LabelField key={itemKey} {...item} form={form} />);
                    break;
                case "LabelSelectTextGroup":
                    tree.push(<LabelSelectTextGroup key={itemKey} {...item} form={form} />);
                    break;
                case "LabelTextGroup":
                    tree.push(<LabelTextGroup key={itemKey} {...item} form={form} />);
                    break;
                default:
                    continue;
            }
        }

        return tree;
    }

    const handleTriggerClick = React.useCallback(() => setOpenFlag(!open), [open, setOpenFlag]);
    const onOpen = React.useCallback(() => setOpenFlag(true), [setOpenFlag]);
    const onClose = React.useCallback(() => setOpenFlag(false), [setOpenFlag]);

    return (
        <section className={`lighten-4 color-box ${color}`}>
            {
                <Collapsible open={open}
                    trigger={
                        <SectionHeader caption={
                            <span>{caption}<InfoButton info={template[0].info} /></span>
                        } explanation={explanation} collapsible={true} />
                    }
                    handleTriggerClick={handleTriggerClick}
                    onOpen={onOpen}
                    onClose={onClose}
                >
                    <div className="nocolor-box" id={targetId}>
                        <div className={"box-padding row"}>
                            <div key={`${type}`} className="nocolor-box">
                                <div className="box-padding row">
                                    {filledTemplate(template)}
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapsible>
            }
        </section>
    );
}

export default ExpandingSubsection;
