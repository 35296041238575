import React, { useContext, useEffect } from 'react';
import InfoButton from './InfoButton';
import { CcmrContext } from '../App';
import './TextField.css';

export const classForSpanType = function (spanType) {
    switch (spanType) {
        // Note that both col1 + col2 and left + right should each sum to 12
        case "col1":
            return "s12 m6";
        case "col2":
            return "s12 m6";
        case "left":
            return "s12 m4";
        case "right":
            return "s12 m8";
        default:
            return "s12";
    }
};

function TextField(props) {
    const { resizeFields } = useContext(CcmrContext);
    let textAreaRef = React.createRef();
    let shrinkLabel = props.defaultValue && props.defaultValue.length > 0 ? "active" : "";

    useEffect(() => {
        resizeFields([textAreaRef.current]);
    }, [resizeFields, textAreaRef]);

    return (
        <div className={`input-field col ${props.className} ${classForSpanType(props.span)}`}>
            <textarea
                id={props.id}
                className="materialize-textarea"
                ref={textAreaRef}
                type="text"
                defaultValue={props.defaultValue}
            />
            <label
                htmlFor={props.id}
                className={shrinkLabel}
            >
                {props.label}<InfoButton {...props} />
            </label>
        </div>
    );
}

export default TextField;