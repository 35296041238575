import { useState } from "react";
import InfoButton from "./InfoButton.js";
import LabelField from "./LabelField.js";
import MultiSelect from "./MultiSelect.js";
import TextField from "./TextField.js";

function LabelSelectTextGroup(props) {
    const { fieldKey, form, items } = props;
    const labels = (form[fieldKey] ?? "").split("|") ?? [];
    const [checks, setChecks] = useState(Object.fromEntries(
        items.map((item, index) => [`check${index + 1}`, labels.includes(item)])
    ));
    const maxKey = `check${props.items.length}`

    return (
        <div className="group">
            <LabelField span="left">
                <b className="prompt">{props.prompt}</b>
                <InfoButton {...props} />
            </LabelField>
            <MultiSelect
                className="pad-top"
                span="right"
                fieldKey={props.fieldKey}
                items={props.items}
                labels={labels}
                checks={checks}
                setChecks={setChecks}
                form={props.form}
            />
            {
                checks[maxKey]
                    ? <div className="row">
                        <div className="label col s12 m4"></div>
                        <TextField
                            className={props.className}
                            span="right"
                            id={`${props.id}_other`}
                            label={props.label}
                            defaultValue={props.form[`${props.id}_other`] ?? ""}
                        />
                    </div>
                    : null
            }
        </div>
    );
}

export default LabelSelectTextGroup;