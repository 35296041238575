import Section from "../../../Widgets/Section";
import ExpandingSubsection from "../../../Widgets/ExpandingSubsection";
import './ContributionsSection.css';
import {
    contribCaption, contribCaptionI, contribCaptionII,
    contribCaptionIII, contribExplanation, contribInfoI,
    contribInfoII, contribInfoIII,
    contributionsNextButtonLabel, describeBriefly, describeOther,
    dimensionsList, labelPurpose,
    labelSpecificAccomplishment, methodList, natureList, prompt3a2,
    prompt3a3, prompt3a5, prompt3a5b, prompt3b1, prompt3b2,
    prompt3c, promptSpecificAccomplishment,
    purposeList, triptychInfo, triptychInfoChildren, triptychInfoHeader,
    visionList
} from "./UserVisibleText";

// Templates for subsections
const modelTemplate = [
    { type: "model", caption: contribCaptionI, color: "blue lighten-4", info: contribInfoI },
    {
        type: "LabelTextGroup", prompt: promptSpecificAccomplishment,
        id: "i_accomplish", label: labelSpecificAccomplishment,
    },
    { type: "LabelTextGroup", prompt: prompt3a2, id: "i_purpose", label: labelPurpose },
    { type: "LabelField", className: "explain-text", span: "full" },
    {
        type: "DashedBorderGroup", prompt: prompt3a3, fieldKey: "i_nature",
        items: natureList, id: "i_nature", label: describeOther
    },
    {
        type: "DashedBorderGroup2Column",
        prompt: prompt3a5,
        info: triptychInfo,
        infoHeader: triptychInfoHeader,
        infoChildren: triptychInfoChildren,
        fieldKey: "triptych",
        items: dimensionsList,
        fields: ["i_semantics", "i_modeling", "i_linguistics"],
        id: "triptych",
        label: prompt3a5b
    }
];
const methodTemplate = [
    { type: "method", caption: contribCaptionII, color: "green", info: contribInfoII },
    {
        type: "LabelTextGroup", prompt: promptSpecificAccomplishment,
        id: "ii_accomplish", label: labelSpecificAccomplishment
    },
    {
        type: "DashedBorderGroup", prompt: prompt3b1, fieldKey: "ii_purpose",
        items: purposeList, id: "ii_purpose", label: describeOther
    },
    {
        type: "DashedBorderGroup", prompt: prompt3b2, fieldKey: "ii_nature",
        items: methodList, id: "ii_nature", label: describeOther
    }
];
const visionTemplate = [
    { type: "vision", caption: contribCaptionIII, color: "pink", info: contribInfoIII },
    {
        type: "LabelTextGroup", prompt: promptSpecificAccomplishment,
        id: "iii_accomplish", label: labelSpecificAccomplishment
    },
    {
        type: "LabelSelectTextGroup", className: "tight-bottom", prompt: prompt3c,
        fieldKey: "iii_contrib_type", items: visionList, id: "iii_contrib_type", label: describeBriefly
    }
];

function ContributionsSection(props) {
    const {
        form, stepThreeVisible, setStepThreeVisible, setStepFourVisible,
        typeOneVisible, typeTwoVisible, typeThreeVisible,
        setTypeOneVisible, setTypeTwoVisible, setTypeThreeVisible
    } = props;

    function moveToStepFour(event) {
        event.preventDefault();

        setStepThreeVisible(false);
        setStepFourVisible(true);
    }

    return (
        <Section targetId="form-contributions"
            caption={contribCaption}
            collapsible={true}
            nomargin={true}
            explanation={contribExplanation}
            color="brown lighten-4"
            container={true}
            open={stepThreeVisible}
            setOpenFlag={setStepThreeVisible}
        >
            <ExpandingSubsection
                targetId="typeI"
                template={modelTemplate}
                open={typeOneVisible}
                setOpenFlag={setTypeOneVisible}
                form={form}
            />
            <ExpandingSubsection
                targetId="typeII"
                template={methodTemplate}
                open={typeTwoVisible}
                setOpenFlag={setTypeTwoVisible}
                form={form}
            />
            <ExpandingSubsection
                targetId="typeIII"
                template={visionTemplate}
                open={typeThreeVisible}
                setOpenFlag={setTypeThreeVisible}
                form={form}
            />

            <div className="flex nomargin">
                <div>
                </div>
                <div className="top-margin">
                    <button className="btn waves-effect waves-light brown end-margin" span="full"
                        onClick={moveToStepFour}
                    >
                        {contributionsNextButtonLabel}
                        <i className="material-icons right">arrow_forward</i>
                    </button>
                </div>
            </div>
        </Section>
    );
}

export default ContributionsSection;