import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "react-materialize";
import "./LoginButton.css";

const LoginButton = () => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    const handleLogin = async () => {
        await loginWithRedirect({
            prompt: "login",
            screen_hint: "signup",
            appState: {
                returnTo: "/",
            },
        });
    };

    return (
        !isAuthenticated &&
        <Button
            className="blue padding-end"
            waves="light"
            small
            onClick={handleLogin}
        >
            Log In
        </Button>
    );
};

export default LoginButton;