import LabelSelectTextGroup2Column from "./LabelSelectTextGroup2Column";
import "./LabelTextGroup.css";

function DashedBorderGroup2Column(props) {
    return (
        <div className="group">
            <div className="bottom-border col s12">&nbsp;</div>
            <LabelSelectTextGroup2Column {...props} />
        </div>
    );
}

export default DashedBorderGroup2Column;