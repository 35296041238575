import { classForSpanType } from "./TextField";

function LabelField(props) {
    return (
        <div className={`label col ${props.className} ${classForSpanType(props.span)}`}>
            {props.children}
        </div>
    );
}

export default LabelField;