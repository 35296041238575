import LabelSelectTextGroup from "./LabelSelectTextGroup.js";
import "./LabelTextGroup.css";

function DashedBorderGroup(props) {
    return (
        <div className="group">
            <div className="bottom-border col s12">&nbsp;</div>
            <LabelSelectTextGroup {...props} />
        </div>
    );
}

export default DashedBorderGroup;