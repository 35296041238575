const FORM_FIELDS = Object.freeze([
    "title",
    "authors",
    "biblio",
    "communities",
    "key_questions",
    "central_contrib",
    "relevant_cm",
    "users",
    "domain",
    "i_accomplish",
    "i_purpose",
    "i_nature",
    "i_nature_other",
    "i_semantics",
    "i_modeling",
    "i_linguistics",
    "ii_accomplish",
    "ii_purpose",
    "ii_purpose_other",
    "ii_nature",
    "ii_nature_other",
    "iii_accomplish",
    "iii_contrib_type",
    "iii_contrib_type_other",
    "additional_info"
]);

function emptyForm(initialForm) {
    let form = typeof initialForm === "object" ? initialForm : {};

    FORM_FIELDS.forEach(function (key) {
        form[key] = "";
    });

    return form;
}

function formParameters(form) {
    let f = {};

    FORM_FIELDS.forEach(function (key) {
        f[key] = form[key] ?? null;
    });

    return FORM_FIELDS.map((key) => f[key]);
}

function isTypeI(form) {
    return (
        form?.i_accomplish ||
        form?.i_purpose ||
        form?.i_nature ||
        form?.i_nature_other ||
        form?.i_semantics ||
        form?.i_modeling ||
        form?.i_linguistics
    );
}

function isTypeII(form) {
    return (
        form?.ii_accomplish ||
        form?.ii_purpose ||
        form?.ii_purpose_other ||
        form?.ii_nature ||
        form?.ii_nature_other
    );
}

function isTypeIII(form) {
    return form?.iii_accomplish || form?.iii_contrib_type || form?.iii_contrib_type_other;
}

export { FORM_FIELDS, emptyForm, formParameters, isTypeI, isTypeII, isTypeIII };
