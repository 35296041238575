import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Dropdown, Preloader } from "react-materialize";
import LoginButton from "./LoginButton";
import SignupButton from "./SignUpButton";
import './UserProfile.css';

const UserProfile = () => {
    const { isAuthenticated, isLoading, logout, user } = useAuth0();

    if (isLoading) {
        return (
            <span className="align-end small-alt">
                <Preloader
                    className="placeholder"
                    active
                    color="blue"
                    flashing={true}
                    size="small"
                />
            </span>
        );
    }

    if (isAuthenticated && (user.name || user.picture)) {
        function handleLogout() {
            logout({ returnTo: window.location.origin });
        }

        return (
            <span className="align-end small-alt padding-left">
                <Dropdown
                    id="profile_dd"
                    options={{
                        alignment: 'right',
                        autoTrigger: true,
                        closeOnClick: true,
                        constrainWidth: false,
                        coverTrigger: false,
                        hover: false,
                        inDuration: 150,
                        outDuration: 250
                    }}
                    trigger={<img src={user.picture} alt={user.name} className="profile" referrerPolicy="no-referrer" />}
                >
                    <div>
                        <span className="emphasized">Logged in as:</span><br />
                        <span className="regular">{user.name}</span><br />
                        <span className="regular">{user.email}</span>
                    </div>
                    <Button
                        className="blue padding-top"
                        waves="light"
                        small
                        onClick={handleLogout}
                    >
                        Log out
                    </Button>
                </Dropdown>
            </span>
        )
    }

    // If the user is not logged in or signed up, display these buttons
    return (<span><LoginButton /><SignupButton /></span>);
};

export default UserProfile;