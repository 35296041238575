import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import CcmrForm from "./Routes/Form/CcmrForm.js";
import FormList from "./Routes/FormList/FormList.js";
import UserProfile from "./Widgets/UserProfile";
import { Container, Icon, NavItem } from "react-materialize";
import Feedback from "./Widgets/Feedback.js";
import EditForm from "./Routes/Form/EditForm/EditForm.js";
import { emptyForm } from "./Ccmr3Form.mjs";
import { useAuth0 } from "@auth0/auth0-react";
import { BASE_URL } from "./Constants";
import FeedbackList from "./Routes/Feedback/FeedbackList";
import M from "materialize-css";
import Navbar from "./CustomMaterialize/Navbar";
import "./App.css";

const title = "Characterizing Conceptual Modeling Research";
const CcmrContext = createContext();

function App() {
    const { getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        async function getAccessToken() {
            try {
                const silentToken = await getAccessTokenSilently();

                setToken(silentToken);
            } catch (e) {
                console.error("Error getting access token", e);
            }
        }

        getAccessToken();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        async function getAdminStatus() {
            if (token !== null) {
                await fetch(`${BASE_URL}isAdmin`, {
                    cache: "no-cache",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                })
                    .then(response => response.json())
                    .then(
                        (result) => {
                            if (result.isAdmin) {
                                setIsAdmin(true);
                            } else {
                                console.error("Error fetching isAdmin", result);
                            }
                        },

                        (errorResult) => {
                            console.error("Error fetching isAdmin", errorResult);
                        }
                    );
            }
        }

        getAdminStatus();
    }, [token]);

    const resizeFields = useCallback((fields) => {
        fields.forEach((field) => {
            if (typeof M.textareaAutoResize === "function") {
                if (typeof field === "string") {
                    M.textareaAutoResize(document.getElementById(field));
                } else if (typeof field === "object") {
                    M.textareaAutoResize(field.current || field);
                }
            }
        });

        M.updateTextFields();
    }, []);

    const contextObject = useMemo(
        () => ({ token, resizeFields }),
        [token, resizeFields]
    );

    let feedbackLink = (isAdmin) ? (
        <Link to="/viewFeedback" className="navbar">
            <Icon>
                summarize
            </Icon>
        </Link>
    ) : null;

    const addLink = (token) ? (
        <Link to="/newForm" className="navbar">
            <Icon>
                add
            </Icon>
        </Link>
    ) : null;

    return (
        <CcmrContext.Provider value={contextObject}>
            <BrowserRouter>
                <main>
                    <header className="navbar-fixed">
                        <Navbar
                            id="mainNav"
                            brand={<span className="blue-grey-text text-darken-4" title={title}>&nbsp;&nbsp;</span>}
                            alignLinks="right"
                            className="blue lighten-4"
                            options={{
                                draggable: true,
                                edge: "right",
                                inDuration: 250,
                                onCloseEnd: null,
                                onCloseStart: null,
                                onOpenEnd: null,
                                onOpenStart: null,
                                outDuration: 200,
                                preventScrolling: true
                            }}
                        >
                            <Link to="/" className="navbar">
                                <Icon>
                                    list
                                </Icon>
                            </Link>
                            <Link to="/search" className="navbar">
                                <Icon>
                                    search
                                </Icon>
                            </Link>
                            {addLink}
                            {feedbackLink}
                            <NavItem
                                className="modal-trigger navbar"
                                href="#feedbackModal"
                            >
                                <Icon>
                                    chat
                                </Icon>
                            </NavItem>
                            <UserProfile />
                        </Navbar>
                    </header>
                    <Routes>
                        <Route path="/" element={<FormList />} />
                        <Route path="/forms/:formId" element={<CcmrForm />} />
                        <Route path="/viewForm/:formId" element={<CcmrForm view={true} />} />
                        <Route
                            path="/newForm"
                            element={<EditForm
                                form={emptyForm({ edit: "edit" })}
                            />}
                        />
                        <Route path="/search" element={<Container><br /><h5>Coming soon</h5></Container>} />
                        <Route path="/viewFeedback" element={<FeedbackList />} />
                    </Routes>
                    <Feedback />
                </main>
            </BrowserRouter>
        </CcmrContext.Provider>
    );
}

export { CcmrContext };
export default App;