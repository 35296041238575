import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "materialize-css";
import { Button, Icon, Modal } from "react-materialize";
import { BASE_URL } from "../Constants.js";
import TextField from "./TextField.js";
import { CcmrContext } from "../App.js";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function Feedback() {
    const { resizeFields, token } = useContext(CcmrContext);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const handleReCaptchaVerify = useCallback(async () => {
        if (executeRecaptcha) {
            setRecaptchaToken(await executeRecaptcha("feedback"));
        }
    }, [executeRecaptcha]);

    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    function submitFeedback() {
        const message = document.getElementById("feedback").value ?? "";
        const location = window.location.href ?? "";

        if (message.length <= 0) {
            return;
        }

        const body = JSON.stringify({
            message,
            location,
            recaptchaToken,
        });

        fetch(`${BASE_URL}feedback`, {
            method: "POST",
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body
        })
            .then(response => response.json())
            .then(
                () => {
                    toast({ html: "Thank you for your feedback" }, 3000);
                },

                (errorResult) => {
                    console.error("Error", errorResult);
                    toast({ html: `Error sending feedback: ${errorResult}` }, 5000);
                }
            );

        document.getElementById("feedback").value = "";
        document.querySelectorAll("label[for='feedback'].active").forEach((label) => {
            label.classList.remove("active");
        });
        resizeFields(["feedback"]);
        handleReCaptchaVerify();
    }

    return (
        <Modal
            id="feedbackModal"
            header="Give Feedback"
            actions={[
                <Button key="b1" modal="close" node="button" onClick={submitFeedback}>Submit <Icon right>send</Icon></Button>,
                <Button key="b2" modal="close" node="button" className="padding-left">Cancel</Button>
            ]}
        >
            <TextField
                id="feedback"
                label="Enter feedback you wish to share"
            />
        </Modal>
    );
}

export default Feedback;