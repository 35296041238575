import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-materialize";
import { CcmrContext } from "../../App.js";
import { BASE_URL } from "../../Constants.js";

function FeedbackList() {
    const { token } = useContext(CcmrContext);
    const [feedbackList, setFeedbackList] = useState({});

    useEffect(() => {
        async function fetchFeedbackList() {
            fetch(`${BASE_URL}feedback`, {
                headers: (token) ? { Authorization: `Bearer ${token}` } : {},
            })
                .then(response => response.json())
                .then(
                    (result) => {
                        setFeedbackList(result);
                    },

                    (error) => {
                        console.error("Error", error);
                    }
                );
        }

        fetchFeedbackList();
    }, [token]);

    function path(url) {
        const protocolPrefix = "https://";

        if (url.length > protocolPrefix.length) {
            const index = url.indexOf("/", protocolPrefix.length);

            if (index > 0) {
                return url.substring(index);
            }
        }

        return url;
    }

    let feedbackRows = [];

    if (feedbackList.feedback) {
        feedbackList.feedback.forEach(record => {
            const user = record.name
                ? `${record.name} (${record.email})`
                : "Anonymous";
            const date = new Date(record.created_at);
            const locale = Intl.NumberFormat().resolvedOptions().locale;
            const dateOptions = {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: false,
                timeZoneName: "short",
            }

            feedbackRows.push(
                <tr key={record.id}>
                    <td>{record.message}</td>
                    <td>{path(record.location)}</td>
                    <td>{date.toLocaleString(locale, dateOptions)}</td>
                    <td>{user}</td>
                </tr>
            );
        });
    }

    return (
        <div className="container white padding-top">
            <div className="row padding-top">
                <div className="row">
                    <div className="col s12"><h5>User Feedback</h5></div>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th data-field="title">Message</th>
                            <th data-field="authors">Location</th>
                            <th data-field="other">Date/Time</th>
                            <th data-field="other">User</th>
                        </tr>
                    </thead>
                    <tbody>
                        {feedbackRows}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default FeedbackList;