import React from 'react';
import ReactDOM from 'react-dom';
// In Node 18, use this:
// import { createRoot } from 'react-dom/client';
// And don't call render on ReactDOM below.
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import './index.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// const container = document.getElementById('root');
// const root = createRoot(container);
ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey="6LdQvmkkAAAAAC1dCg0u5z0GDc3SJFcqNJFxsa_a"
      scriptProps={{
        defer: true,
      }}
    >
      <Auth0Provider
        domain="dev-qvtj87vnpomiphny.us.auth0.com"
        clientId="KmqUpGKWtiM2AbBgFg50QxN0iooyQIAx"
        redirectUri={window.location.origin}
        audience={
          (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
            ? "https://localhost:9443/ccmr-api/v1/"
            : "https://ccmr.byu.edu:9443/ccmr-api/v1/"
        }
      >
        <App />
      </Auth0Provider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
