import { useState } from 'react';
import TextField, { classForSpanType } from './TextField.js';
import './MultiSelectTextField.css';

function MultiSelectTextField(props) {
    const { className, fieldKey, fields, items, form, label, span } = props;
    const [checks, setChecks] = useState(Object.fromEntries(
        items.map((_, index) => [`check${index + 1}`, form[fields[index]] ? true : false])
    ));
    const rows = [];
    let index = 0;

    function handleChange(name, key) {
        const newChecks = Object.assign({}, checks);

        newChecks[key] = document.querySelectorAll(`input[name="${name}"]`)[0].checked;
        setChecks(newChecks);
    }

    for (const value of items) {
        index += 1;

        const key = "check" + index;
        const classExpression = checks[key] ? "col s12 m4" : "col s12";
        const name = `${fieldKey}-${index}`;

        rows.push(
            <div key={`dk-${fieldKey}-${index}`}>
                <label className={classExpression} key={name}>
                    <input
                        type="checkbox"
                        name={name}
                        value={index}
                        onChange={() => { handleChange(name, key); }}
                        checked={checks[key]}
                    />
                    <span>{value}</span>
                </label>
                {checks[key]
                    ? <TextField
                        className={`tight-header ${props.className}`}
                        key={`key${name}`}
                        span="right"
                        id={fields[index - 1]}
                        label={label}
                        defaultValue={form[fields[index - 1]] ?? ""}
                    />
                    : null
                }
            </div>
        );

        if (index < items.length) {
            rows.push(<br key={`br${fieldKey}-${index}`} />);
        }
    }

    return (
        <div className={`col ${className} ${classForSpanType(span)}`}>
            {rows}
        </div>
    );
}

export default MultiSelectTextField;