import { Button, Icon, } from "react-materialize";
import DblpSearchModal from "./DblpSearchModal.js";
import Section from "../../../Widgets/Section.js";
import TextField from "../../../Widgets/TextField.js";
import {
    bibCaption, bibCommunityInfoChildren, bibCommunityInfoHeader,
    bibCommunityTooltip, bibLabelAuthors, bibLabelBiblio, bibLabelCommunity,
    bibLabelTitle, nextButtonLabel
} from "./UserVisibleText.js";
import "./BibliographicSection.css"

const color = "indigo";

function BibliographicSection(props) {
    const { form, setStepOneVisible, setStepTwoVisible, stepOneVisible } = props;

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noreferrer')
        if (newWindow) newWindow.opener = null
    }

    function moveToStepTwo(event) {
        event.preventDefault();

        setStepOneVisible(false);
        setStepTwoVisible(true);
    }

    return (
        <Section targetId="form-identify"
            caption={bibCaption}
            collapsible={true}
            color={color}
            nomargin={true}
            open={stepOneVisible}
            setOpenFlag={setStepOneVisible}
        >
            <TextField
                id="title"
                span="col1"
                label={bibLabelTitle}
                defaultValue={form?.title ?? ""}
            />
            <TextField
                id="authors"
                span="col2"
                label={bibLabelAuthors}
                defaultValue={form?.authors ?? ""}
            />
            <TextField
                id="biblio"
                span="full"
                label={bibLabelBiblio}
                defaultValue={form?.biblio ?? ""}
            />
            <TextField
                id="communities"
                span="full"
                label={bibLabelCommunity}
                info={bibCommunityTooltip}
                infoHeader={bibCommunityInfoHeader}
                infoChildren={bibCommunityInfoChildren}
                defaultValue={form?.communities ?? ""}
            />
            <div className="flex nomargin">
                <div>
                    <Button
                        node="button"
                        waves="light"
                        className={`end-margin ${color}`}
                        onClick={() => openInNewTab('https://dblp.org/lookup')}
                    >
                        Go to DBLP <Icon right>link</Icon>
                    </Button>
                    <DblpSearchModal color={color} />
                </div>
                <div>
                    <button
                        className={`btn waves-effect waves-light ${color} end-margin`}
                        span="full"
                        onClick={moveToStepTwo}
                    >
                        {nextButtonLabel}
                        <i className="material-icons right">arrow_forward</i>
                    </button>
                </div>
            </div>
        </Section>
    );
}

export default BibliographicSection;