import { Container, Table } from "react-materialize";
import { Link } from "react-router-dom";
import {
    additionalHeader,
    bibHeader, bibLabelAuthors, bibLabelBiblio, bibLabelCommunity, bibLabelTitle,
    contribCaptionI, contribCaptionII, contribCaptionIII,
    contribHeader, contribNature,
    dimensionsList,
    keyLabelContribution, keyLabelDomain, keyLabelQuestions, keyLabelRelevance, keyLabelUsers,
    labelOther, labelPurpose, labelSpecificAccomplishment,
} from "../EditForm/UserVisibleText.js";
import { isTypeI, isTypeII, isTypeIII } from "../../../Ccmr3Form.mjs";
import "./ShowForm.css";

/*------------------------------------------------------------------------
 *                      LOCAL WIDGETS
 */
function LabelList(props) {
    const { label, text, other } = props;
    const fields = text?.split("|") ?? [];
    let counter = 0;

    const checkboxes = fields.map((field, index) => {
        const checkbox = field ? <span>&#x2611;</span> : null;

        counter += 1;

        return (
            <div key={`ll-${counter}`} >{checkbox} <span>{field}</span></div>
        );
    });

    if (other) {
        checkboxes.push(
            <div key="ll-other"><span className="emphasis">{labelOther}:</span> <span>{other}</span></div>
        );
    }

    return (
        <tr>
            <td className="label">{label}</td>
            <td className="data">{checkboxes}</td>
        </tr>
    );
}

function LabelText(props) {
    const { label, text } = props;

    return (
        <tr>
            <td className="label">{label}</td>
            <td className="data" dangerouslySetInnerHTML={{ __html: text.replaceAll("\n", "<br />") }} />
        </tr>
    );
}

function TableDivider(props) {
    const { button, color, text } = props;
    let buttonElement = null;

    if (button) {
        buttonElement = (<div className="right-align">{button}</div>);
    }

    return (
        <tr>
            <td colSpan="2" className={`section-divider ${color} lighten-4`}>
                <div className="flexer">
                    <div>{text}</div>
                    {buttonElement}
                </div>
            </td>
        </tr>
    );
}

function TextRow(props) {
    const { color, text } = props;

    return (
        <tr>
            <td colSpan="2" className={`${color} lighten-4`}>{text}</td>
        </tr>
    );
}

/*------------------------------------------------------------------------
 *                      CONSTANTS
 */
const locale = Intl.NumberFormat().resolvedOptions().locale;
const dateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
    timeZoneName: "short",
};

/*------------------------------------------------------------------------
 *                      HELPER FUNCTIONS
 */
function formatDateUser(form) {
    let date = parseISOString(form.created_at);
    let formattedString = `Created ${date.toLocaleString(locale, dateOptions)}`;

    if (form.created_by) {
        formattedString += ` by <a href="mailto:${form.created_email}">${form.created_by}</a>`;
    }

    if (form.updated_at) {
        date = parseISOString(form.updated_at);
        formattedString += `, updated ${date.toLocaleString(locale, dateOptions)}`;

        if (form.created_by !== form.updated_by) {
            if (form.updated_user_id && form.updated_user_id !== form.created_user_id) {
                formattedString += ` by <a href="mailto:${form.updated_email}">${form.updated_by}</a>`;
            }
        }
    }

    return formattedString;
}

function parseISOString(s) {
    const b = s.split(/\D+/);
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}

/*------------------------------------------------------------------------
 *                      MAIN WIDGET - ShowForm
 */
function ShowForm(props) {
    const { form } = props;
    const formRows = [];
    let key = 0;
    let haveContribution = false;
    let editButton = null;

    if (form.edit === "edit") {
        editButton = <Link to={`/forms/${form.id}`} className="btn waves-effect waves-light">Edit Form</Link>;
    }

    formRows.push(<TableDivider key={`k${key++}`} text={bibHeader} color="indigo" button={editButton} />);
    formRows.push(<LabelText key={`k${key++}`} label={`${bibLabelTitle}:`} text={form?.title ?? ""} />);
    formRows.push(<LabelText key={`k${key++}`} label={`${bibLabelAuthors}:`} text={form?.authors ?? ""} />);
    formRows.push(<LabelText key={`k${key++}`} label={`${bibLabelBiblio}:`} text={form?.biblio ?? ""} />);
    formRows.push(<LabelText key={`k${key++}`} label={`${bibLabelCommunity}:`} text={form?.communities ?? ""} />);
    formRows.push(<LabelText key={`k${key++}`} label={`${keyLabelQuestions}:`} text={form?.key_questions ?? ""} />);
    formRows.push(<LabelText key={`k${key++}`} label={`${keyLabelContribution}:`} text={form?.central_contrib ?? ""} />);
    formRows.push(<LabelText key={`k${key++}`} label={`${keyLabelRelevance}:`} text={form?.relevant_cm ?? ""} />);
    formRows.push(<LabelText key={`k${key++}`} label={`${keyLabelUsers}:`} text={form?.users ?? ""} />);
    formRows.push(<LabelText key={`k${key++}`} label={`${keyLabelDomain}:`} text={form?.domain ?? ""} />);
    formRows.push(<TableDivider key={`k${key++}`} text={contribHeader} color="brown" />);

    if (isTypeI(form)) {
        haveContribution = true;

        formRows.push(<TextRow key={`k${key++}`} text={contribCaptionI} color="blue" />);
        formRows.push(<LabelText key={`k${key++}`} label={`${labelSpecificAccomplishment}:`} text={form?.i_accomplish ?? ""} />);
        formRows.push(<LabelText key={`k${key++}`} label={`${labelPurpose}:`} text={form?.i_purpose ?? ""} />);
        formRows.push(<LabelList
            key={`ki${key++}`}
            label={`${contribNature}:`}
            text={form?.i_nature ?? ""}
            other={form?.i_nature_other ?? ""}
        />);

        if (form?.i_semantics) {
            formRows.push(<LabelText key={`k${key++}`} label={`${dimensionsList[0]}:`} text={form.i_semantics} />);
        }

        if (form?.i_modeling) {
            formRows.push(<LabelText key={`k${key++}`} label={`${dimensionsList[1]}:`} text={form.i_modeling} />);
        }

        if (form?.i_linguistics) {
            formRows.push(<LabelText key={`k${key++}`} label={`${dimensionsList[2]}:`} text={form.i_linguistics} />);
        }
    }

    if (isTypeII(form)) {
        haveContribution = true;

        formRows.push(<TextRow key={`k${key++}`} text={contribCaptionII} color="green" />);
        formRows.push(<LabelText key={`k${key++}`} label={`${labelSpecificAccomplishment}:`} text={form?.ii_accomplish ?? ""} />);
        formRows.push(<LabelList
            key={`kii${key++}`}
            label={`${labelPurpose}:`}
            text={form?.ii_purpose ?? ""}
            other={form?.ii_purpose_other ?? ""}
        />);
        formRows.push(<LabelList
            key={`kiin${key++}`}
            label={`${contribNature}:`}
            text={form?.ii_nature ?? ""}
            other={form?.ii_nature_other ?? ""}
        />);
    }

    if (isTypeIII(form)) {
        haveContribution = true;

        formRows.push(<TextRow key={`k${key++}`} text={contribCaptionIII} color="pink" />);
        formRows.push(<LabelText key={`k${key++}`} label={`${labelSpecificAccomplishment}:`} text={form?.iii_accomplish ?? ""} />);
        formRows.push(<LabelList
            key={`kiii${key++}`}
            label={`${labelPurpose}:`}
            text={form?.iii_contrib_type ?? ""}
            other={form?.iii_contrib_type_other ?? ""}
        />);
    }

    if (!haveContribution) {
        formRows.push(<TextRow key={`k${key++}`} text="Details about the contribution have not yet been entered" color="white" />);
    }

    if (form?.additional_info) {
        formRows.push(<TableDivider key={`k${key++}`} text={additionalHeader} color="deep-orange" />);
        formRows.push(<TextRow key={`k${key++}`} text={form?.additional_info} />);
    }

    return (
        <Container>
            <Table className="small-padding-bottom" hoverable={true}>
                <tbody>
                    {formRows}
                </tbody>
            </Table>
            <div className="row padding-bottom">
                <div className="col s10 small-text" dangerouslySetInnerHTML={{ __html: formatDateUser(form) }} />
                <div className="col s2 align-end">
                    {editButton}
                </div>
            </div>
        </Container>
    );
}

export default ShowForm;