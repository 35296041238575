import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../Constants.js";
import EditForm from "./EditForm/EditForm.js";
import ShowForm from "./ShowForm/ShowForm.js";
import { CcmrContext } from "../../App.js";
import "./CcmrForm.css";

function CcmrForm(props) {
    const { view } = props;
    const { formId } = useParams();
    const { token } = useContext(CcmrContext);
    const [form, setForm] = useState({});

    useEffect(() => {
        if (formId > 0) {
            async function fetchForm() {
                await fetch(`${BASE_URL}forms/${formId}`, {
                    headers: (token) ? { Authorization: `Bearer ${token}` } : {},
                })
                    .then(response => response.json())
                    .then(
                        (result) => {
                            if (result.form) {
                                setForm(result.form);
                            } else {
                                console.error("Error", result);
                            }
                        },

                        (errorResult) => {
                            console.error("Error", errorResult);
                        }
                    );
            }

            fetchForm();
        }
    }, [formId, token]);

    if (!form.edit) {
        return <div>Loading...</div>;
    }

    return (
        (form.edit === "edit" && !view)
            ? <EditForm form={form} formId={formId} />
            : <ShowForm form={form} />
    );
}

export default CcmrForm;