import './ExpandCollapse.css';

function ExpandCollapse(props) {
    return (
        props.collapsible
        ?
            <div className="expand-collapse">
                <button>
                    <i className="material-icons right">arrow_drop_up</i>
                </button>
            </div>
        :
            null
    );
}

export default ExpandCollapse;