import Section from "../../../Widgets/Section";
import TextField from "../../../Widgets/TextField";
import {
    keyQuestion1, keyQuestion2, keyQuestion3, keyQuestionDomain, keyQuestions, keyQuestionUsers, nextButtonLabel
} from "./UserVisibleText";
import "./KeyQuestionsSection.css";

function KeyQuestionsSection(props) {
    const { form, setStepThreeVisible, setStepTwoVisible, stepTwoVisible } = props;

    function moveToStepTwo(event) {
        event.preventDefault();

        setStepTwoVisible(false);
        setStepThreeVisible(true);
    }

    return (
        <Section targetId="form-summary" caption={keyQuestions} color="amber"
            collapsible={true} nomargin={true}
            open={stepTwoVisible}
            setOpenFlag={setStepTwoVisible}
        >
            <TextField
                span="full"
                id="key_questions"
                label={keyQuestion1}
                defaultValue={form?.key_questions ?? ""}
            />
            <TextField
                span="full"
                id="central_contrib"
                label={keyQuestion2}
                defaultValue={form?.central_contrib ?? ""}
            />
            <TextField
                span="full"
                id="relevant_cm"
                label={keyQuestion3}
                defaultValue={form?.relevant_cm ?? ""}
            />
            <h5 className="section-divider">Other significant aspects of the work</h5>
            <TextField
                id="users"
                span="full"
                label={keyQuestionUsers}
                defaultValue={form?.users ?? ""}
            />
            <TextField
                id="domain"
                span="full"
                label={keyQuestionDomain}
                defaultValue={form?.domain ?? ""}
            />
            <div className="flex nomargin">
                <div>
                </div>
                <div>
                    <button className="btn waves-effect waves-light amber end-margin" span="full"
                        onClick={moveToStepTwo}
                    >
                        {nextButtonLabel}
                        <i className="material-icons right">arrow_forward</i>
                    </button>
                </div>
            </div>
        </Section>
    );
}

export default KeyQuestionsSection;